import React, {Component} from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        Website built by Josh Lopez. All rights reserved
      </div>
    );
  }
}

export default Footer;