import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
setInterval(()=>{
  let cursor = document.getElementById("blink");
  if (!!cursor && cursor.style.visibility !== "hidden")
    cursor.style.visibility = "hidden";
  else if (!!cursor)
    cursor.style.visibility = "visible";
}, 500);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
